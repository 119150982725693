
import { defineComponent, onMounted, ref, watch } from "vue";
// import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import { useRoute } from "vue-router";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import Feed from "@/presentation/components/Feed.vue";


export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_PANELS,
  components: {
    CreateVaccine,
    AddWeight,
    Feed
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const barnyardId = route.params["id"];

    const originalAnimalList = ref<AnimalListModel[]>([]);
    const animalList = ref<AnimalListModel[]>([]);

    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }

    watch(
      () => store.getters.newFood,
      () => {
        if (store.getters.newFood) fetchAnimals(1);
      }
    );

    const fetchAnimals = async (page) => {
      animalList.value = [];
      originalAnimalList.value = [];

      const animalListModel: AnimalListModel = {
        animal: {
          profilePhotoUrl: "media/animal/barnyard.jpg",
        },
        page: page,
        pageSize: 10,
        barnyardId: Number(barnyardId),
      };

      animalController
        .animalList(animalListModel)
        .then((response) => {
        if (response.isSuccess) {
            response.getValue().forEach((animal) => {
              animalList.value.push(animal);
              originalAnimalList.value.push(animal);
            });
          } else {
            animalList.value = [];

            originalAnimalList.value = [];
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BARNYARD));
      fetchAnimals(1);
    });

    return {
      PAGE_TITLE_PATH,
      barnyardId,
      originalAnimalList,
    };
  },
});
