<template>
  <div class="card" style="margin-top: 18px">
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                class="nav-link text-active-primary me-6"
                :to="'/barnyard/' + barnyardId + '/animals'"
                active-class="active"
              >
                {{ $t("menuPages.barnyardPanel.tabMenu.animals") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--
            <li class="nav-item" v-if="originalAnimalList.length != 0">
              <router-link
                class="nav-link text-active-primary me-6"
                :to="'/barnyard/' + barnyardId + '/vaccinations'"
                active-class="active"
              >
                {{ $t("menuPages.barnyardPanel.tabMenu.vaccination") }}
              </router-link>
            </li>
            -->
            <li class="nav-item" v-if="originalAnimalList.length != 0">
              <router-link
                class="nav-link text-active-primary me-6"
                :to="'/barnyard/' + barnyardId + '/feeds'"
                active-class="active"
              >
                {{ $t("menuPages.barnyardPanel.tabMenu.feed") }}
              </router-link>
            </li>
            <li class="nav-item" v-if="originalAnimalList.length != 0">
              <router-link
                class="nav-link text-active-primary me-6"
                :to="'/barnyard/' + barnyardId + '/weights'"
                active-class="active"
              >
                {{ $t("menuPages.barnyardPanel.tabMenu.weight") }}
              </router-link>
            </li>
            <!--
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-primary me-6"
                  to="/barnyardFeed"
                  active-class="active"
                >
                  {{ $t("menuPages.barnyardPanel.tabMenu.treatment") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-primary me-6"
                  to="/barnyardFeed"
                  active-class="active"
                >
                  {{ $t("menuPages.barnyardPanel.tabMenu.overheads") }}
                </router-link>
              </li>
            -->
          </ul>
        </div>
        <div class="card-header border-0" style="padding: 0">
          <div class="card-title">
            {{ $t(PAGE_TITLE_PATH.FEED) }}
          </div>
                    <!--begin::Actions-->
                    <div
            class="d-flex align-items-center py-1"
          >
                                   <!--begin::Button-->
                                   <a
              href="#"
              class="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#feed_modal"
              style="margin-right: 5px"
            >
              {{ $t("menuPages.food.button.feedAnimal") }}
            </a>
            <!--end::Button-->
          </div>

          <!--end::Actions-->
        </div>

        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
          <div class="table-responsive">
            <table
              class="
                table
                align-middle
                table-row-dashed
                fs-6
                gy-5
                dataTable
                no-footer
              "
              id="kt_customers_table"
              role="grid"
            >
              <!--begin::Table-->
              <thead>
                <tr
                  class="
                    text-start text-gray-400
                    fw-bolder
                    fs-7
                    text-uppercase
                    gs-0
                  "
                  role="row"
                >
                  <td>#</td>
                  <!-- <td>{{ $t("menuPages.food.barnyard") }}</td>-->
                  <td>{{ $t("menuPages.food.numberOfAnimals") }}</td>
                  <td>{{ $t("menuPages.food.foodName") }}</td>
                  <td>{{ $t("menuPages.food.amount") }}</td>
                  <td>{{ $t("menuPages.food.date") }}</td>
                </tr>
              </thead>

              <tbody class="fw-bold text-gray-600">
                <tr class="odd">
                  <td>1</td>

                  <td>30</td>
                  <td>Arpa</td>
                  <td>30 kilo</td>
                  <td>22.02.2024</td>
                </tr>
              </tbody>
              <!--end::Table-->
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreateVaccine></CreateVaccine>
  <AddWeight></AddWeight>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
// import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import { useRoute } from "vue-router";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import Feed from "@/presentation/components/Feed.vue";


export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_PANELS,
  components: {
    CreateVaccine,
    AddWeight,
    Feed
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const barnyardId = route.params["id"];

    const originalAnimalList = ref<AnimalListModel[]>([]);
    const animalList = ref<AnimalListModel[]>([]);

    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }

    watch(
      () => store.getters.newFood,
      () => {
        if (store.getters.newFood) fetchAnimals(1);
      }
    );

    const fetchAnimals = async (page) => {
      animalList.value = [];
      originalAnimalList.value = [];

      const animalListModel: AnimalListModel = {
        animal: {
          profilePhotoUrl: "media/animal/barnyard.jpg",
        },
        page: page,
        pageSize: 10,
        barnyardId: Number(barnyardId),
      };

      animalController
        .animalList(animalListModel)
        .then((response) => {
        if (response.isSuccess) {
            response.getValue().forEach((animal) => {
              animalList.value.push(animal);
              originalAnimalList.value.push(animal);
            });
          } else {
            animalList.value = [];

            originalAnimalList.value = [];
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BARNYARD));
      fetchAnimals(1);
    });

    return {
      PAGE_TITLE_PATH,
      barnyardId,
      originalAnimalList,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
